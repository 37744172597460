<template>
    <div class="profile-main__img">

        <vueper-slides ref="slides" :visible-slides="sliderSettings" :gap="1" :arrows="slideSettings.arrows" :arrows-outside="slideSettings.arrowsOutside" :bullets-outside="slideSettings.bulletsOutside" :bullets="slideSettings.bullets" :dragging-distance="70" :fixed-height="true">
            <vueper-slide v-for="(slide, i) in slides" :key="i" :image="slide.image" :class="slide.class"/>
        </vueper-slides>

    </div>
</template>

<script>
import 'vueperslides/dist/vueperslides.css';
import { VueperSlides, VueperSlide } from 'vueperslides';
import { screenSizeMixin } from '@/mixins/screenSizeMixin';

export default {
    name: 'ProfileImageCarousel',
    mixins: [screenSizeMixin],
    components: {
        VueperSlide,
        VueperSlides
    },
    props: {
        slides: {
            type: Array,
            required: true,
        default: []
        },
    },
    data() {
        return {
            slideSettings: {
                visibleSlides: 1,
                arrows:true,
                bullets:true,
                arrowsOutside:false,
                bulletsOutside:false
            },
        }
    },
    computed: {
        sliderSettings(){
            if(this.$screenWidth < 991 && this.$screenWidth > 555 && this.slides.length>1){
                if(this.slides.length>3){
                    this.slideSettings.visibleSlides=3;
                }
                else{
                    this.slideSettings.visibleSlides=this.slides.length;
                }
            }
            else{
                this.slideSettings.visibleSlides=1;
            }
            return this.slideSettings.visibleSlides;
        },
    },
    watch: {
        $screenWidth: function(newScreenWidth, oldScreenWidth) {
            if (newScreenWidth !== oldScreenWidth) {
                // Use a setTimeout to reset the slide after the reactivity update
                setTimeout(() => {
                    // Use the goToSlide method to navigate to the first slide
                    this.$refs.slides.goToSlide(0);
                }, 0);
            }
        }
    }
}
</script>
<style lang="scss">
@import '@/scss/_mixins.scss';
@import '@/scss/_variables.scss';

.profile-main__img{

    margin-top: auto;
    margin-bottom: auto;

    @media (max-width: 991px) {
        width: 100%;
        margin-right: auto!important;
        margin-left: auto!important;
    }

    .logo{
        background-size: contain;
        background-color: #fff;
        background-repeat: no-repeat;
        background-origin: content-box;
        padding: 20px;
    }

    .vueperslides--fixed-height { 
        height: 380px; 
        @media (max-width: 770px) {
            height: 330px; 
        }

        @media (max-width: 550px) {
            height: 300px; 
        }

        @media (max-width: 460px) {
            height: 250px; 
        }

        @media (max-width: 410px) {
            height: 200px; 
        }
    }

    .vueperslides__inner{
        background-color: white;
    }

    .vueperslides__bullet .default{
        box-shadow: 0 0 1px rgb(0, 0, 0), 0 0 1px rgba(0, 0, 0, 0.3);
    }
}
</style>